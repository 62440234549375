import { createSessionId, redirectTo, getCurrentUrl } from 'utils/appshell';
import { ResolverProps } from '../middleware';

export default function redirect({ props: { hash, destination, returnBack = false } }: ResolverProps): boolean {
  const redirectOptions: any = {};
  if ('auth' === destination || returnBack) {
    redirectOptions.return_to = encodeURIComponent(getCurrentUrl());
  }
  if ('auth' === destination) {
    destination = 'auth';
    redirectOptions.sessionId = createSessionId();
  }
  redirectTo(destination, redirectOptions, hash);
  return true;
}
