import { UserDataInterface } from 'api/userEnrollInfo';
import { CheckoutDetailsContextType } from 'context/initialState';
import EventManager from '../../EventManager';
import { CheckoutDetailsActions } from 'context/checkoutDetails.context';
import { fromCents } from 'utils';
import { PurchaseBriefLineItemOutput } from 'services/api/client';

const setSegmentPayload = (payload: any) => ({
  coupon: payload.promoCode,
  discount: fromCents(payload.price?.discount || 0),
  productName: payload.title,
  price: fromCents(payload.price?.cost || 0),
  promotionId: payload.promoId,
  sku: payload.sku,
});

const setSegmentHouseholdMembersPayload = (payload: any) => ({
  coupon: payload.promoCode,
  discount: fromCents(
    payload.items?.find((item: PurchaseBriefLineItemOutput) => item.type === 'plan')?.price.discount ?? 0
  ),
  productName: 'Household Member Add-On',
  price: fromCents(payload.items?.find((item: PurchaseBriefLineItemOutput) => item.type === 'plan')?.price.cost || 0),
  promotionId: payload.promoId,
  sku: payload.sku,
});

const setOrderPayload = (actionPayload: UserDataInterface) => {
  const { subscription, extra } = actionPayload;
  return subscription?.order_completed_message?.properties ?? extra?.order_completed_message?.properties;
};

const segmentDispatcher = (state: CheckoutDetailsContextType, action: CheckoutDetailsActions): void => {
  switch (action.type) {
    case 'S_PROMOTION_APPLIED':
      EventManager.dispatch('promotion.clicked', action.payload);
      break;
    case 'S_PAYMENT_INFO_ENTERED':
      EventManager.dispatch('payment.infoEntered', action.payload);
      break;
    case 'S_STEP_VIEWED':
      EventManager.dispatch('checkout.stepViewed', action.payload);
      break;
    case 'S_STEP_COMPLETED':
      EventManager.dispatch('checkout.stepCompleted', action.payload);
      break;
    case 'S_ENROLMENT_STARTED':
      EventManager.dispatch('enrollment.started');
      break;
    case 'S_HOUSEHOLD_MEMBERS_ADD':
      if (action.payload) {
        const payload = setSegmentHouseholdMembersPayload(action.payload);
        EventManager.dispatch('product.clicked', payload);
        EventManager.dispatch('product.added', payload);
      }
      break;
    case 'S_HOUSEHOLD_MEMBERS_REMOVE':
      if (action.payload) {
        const payload = setSegmentHouseholdMembersPayload(action.payload);
        EventManager.dispatch('product.clicked', payload);
        EventManager.dispatch('product.removed', payload);
      }
      break;
    case 'SELECT_PLAN':
      if (!state.selectedPlan?.id) {
        const payload = setSegmentPayload(action.payload);
        EventManager.dispatch('product.clicked', payload);
        EventManager.dispatch('product.added', payload);
      }

      if (state.selectedPlan && state.selectedPlan.id && state.selectedPlan.id !== action.payload.id) {
        const addedPayload = setSegmentPayload(action.payload);
        const removedPayload = setSegmentPayload(state.selectedPlan);
        EventManager.dispatch('product.clicked', addedPayload);
        EventManager.dispatch('product.added', addedPayload);
        EventManager.dispatch('product.removed', removedPayload);
      }
      break;
    case 'SELECT_TRAVEL_PROTECTION':
      if (state.user.travel_protection !== action.payload) {
        if (action.payload && !state.user.travel_protection) {
          const payload = setSegmentPayload(action.payload);
          EventManager.dispatch('product.clicked', payload);
          EventManager.dispatch('product.added', payload);
        }

        if (state.user.travel_protection?.id && !action.payload) {
          const payload = setSegmentPayload(state.user.travel_protection);
          EventManager.dispatch('product.clicked', payload);
          EventManager.dispatch('product.removed', payload);
        }
      }
      break;
    case 'SET_SUBSCRIPTION_DETAILS':
      if (state) {
        const orderPayload = setOrderPayload(action.payload);
        !!orderPayload && EventManager.dispatch('order.completed', orderPayload);
      }
      if (state.selectedPlan && state.selectedPlan.trial && action.payload.user?.subscription) {
        const now = Date.now();
        const days = Math.round((action.payload.user.subscription.expires - now) / (60 * 60 * 24 * 1000));
        const trialPayload = {
          trialPeriodDays: days,
          trialEndDate: action.payload.user.subscription.expires,
          trialPlanName: state.selectedPlan.title,
          trialStartDate: now,
        };
        EventManager.dispatch('trial.started', trialPayload);
      }
      break;
  }
};

export default segmentDispatcher;
