import variables from '@theme/variables.module.scss';

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      black: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      slate: React.CSSProperties['color'];
      successGreen: React.CSSProperties['color'];
      errorRed: React.CSSProperties['color'];
      gray: React.CSSProperties['color'];
      blue: React.CSSProperties['color'];
      orange: React.CSSProperties['color'];
      gold: React.CSSProperties['color'];
      green: React.CSSProperties['color'];
      lightText: React.CSSProperties['color'];
      inActiveText: React.CSSProperties['color'];
      lightGray: React.CSSProperties['color'];
      border: React.CSSProperties['color'];
      darkGray: React.CSSProperties['color'];
      darkestGray: React.CSSProperties['color'];
      mediumGray: React.CSSProperties['color'];
      lightGrayDark: React.CSSProperties['color'];
      lightestGray: React.CSSProperties['color'];
      facebookBlue: React.CSSProperties['color'];
      lightMint: React.CSSProperties['color'];
      mint: React.CSSProperties['color'];
      akkurat: React.CSSProperties['fontFamily'];
      akkuratMono: React.CSSProperties['fontFamily'];
    };
  }
  interface ThemeOptions {
    custom: {
      black: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      slate: React.CSSProperties['color'];
      successGreen: React.CSSProperties['color'];
      errorRed: React.CSSProperties['color'];
      gray: React.CSSProperties['color'];
      blue: React.CSSProperties['color'];
      orange: React.CSSProperties['color'];
      gold: React.CSSProperties['color'];
      green: React.CSSProperties['color'];
      lightText: React.CSSProperties['color'];
      inActiveText: React.CSSProperties['color'];
      lightGray: React.CSSProperties['color'];
      border: React.CSSProperties['color'];
      darkGray: React.CSSProperties['color'];
      darkestGray: React.CSSProperties['color'];
      mediumGray: React.CSSProperties['color'];
      lightGrayDark: React.CSSProperties['color'];
      lightestGray: React.CSSProperties['color'];
      facebookBlue: React.CSSProperties['color'];
      lightMint: React.CSSProperties['color'];
      mint: React.CSSProperties['color'];
      akkurat: React.CSSProperties['fontFamily'];
      akkuratMono: React.CSSProperties['fontFamily'];
    };
  }
}

export default {
  black: variables.black,
  white: variables.white,
  slate: variables.slate,
  successGreen: variables.successGreen,
  errorRed: variables.errorRed,
  gray: variables.gray,
  blue: variables.blue,
  orange: variables.orange,
  gold: variables.gold,
  green: variables.green,
  lightText: variables.lightText,
  inActiveText: variables.inActiveText,
  lightGray: variables.lightGray,
  border: variables.border,
  darkGray: variables.darkGray,
  darkestGray: variables.darkestGray,
  mediumGray: variables.mediumGray,
  lightGrayDark: variables.lightGrayDark,
  lightestGray: variables.lightestGray,
  facebookBlue: variables.facebookBlue,
  lightMint: variables.lightMint,
  mint: variables.mint,
  akkurat: variables.akkurat,
  akkuratMono: variables.akkuratMono,
};
