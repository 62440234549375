import { dbRef } from '../../Firebase';
import { MiddlewareProps } from '../middleware';

type UserAttributes = {
  roles: string[];
  identification?: {
    identity: { status: string };
    military: { status: string };
  };
  verificationStatus: string;
};

export default async function enrollAllowed({ user }: MiddlewareProps): Promise<undefined | Data> {
  if (!user || !user.uid) {
    return {
      action: 'redirect',
      destination: 'auth',
    };
  }
  const doc = await dbRef.collection('veterans').doc(user.uid).get();
  if (!doc.exists) {
    return;
  }
  const { roles = [], identification } = doc.data() as UserAttributes;
  const user_verified =
    'verified' === identification?.identity?.status && 'verified' === identification?.military?.status;
  if (!user_verified) {
    return {
      action: 'redirect',
      destination: 'verify',
      returnBack: true,
      hash: 'welcome',
    };
  }
}
