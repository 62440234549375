import { PlanInterface, PreviewDataInterface, UserDataInterface, UserInterface } from 'api/userEnrollInfo';
import {
  PurchaseBriefOverviewOutputConfig,
  PurchaseBriefLineItemOutputTypeEnum,
  PriceOutputCurrencyEnum,
  TimePeriodOutputUnitEnum,
  BrandQueueOutput,
} from 'services/api/client';
import { MemberTimelinesInterface } from './checkoutDetails.context';

type ReviewDetailsType = {
  donate: {
    donate: string;
    checkedB: boolean;
    donate_other: null | number;
  };
};

type CheckoutDetailsContextType = {
  brand_queue: BrandQueueOutput | null;
  selectedProducts: Record<string, string[]>;
  selectedPlan: PlanInterface | null;
  details: ReviewDetailsType;
  user: UserInterface;
  memberTimelines?: MemberTimelinesInterface;
  subscriptionDetails: UserDataInterface | null;
  previewDetails: PreviewDataInterface;
  config: PurchaseBriefOverviewOutputConfig;
  paymentDetails?: { paymentMethod: string; step: number };
  travelProtection?: string[];
  promoId?: number;
  promoCode?: string;
};

const initialPrice = {
  currency: PriceOutputCurrencyEnum.Usd,
  cost: 0,
  discount: 0,
  amount: 0,
};

const initItem = {
  title: '',
  description: '',
  type: PurchaseBriefLineItemOutputTypeEnum.Plan,
  price: initialPrice,
  discount_reason: { type: '', description: '' },
};

const initLineGroup = {
  user: {
    id: null,
    member_id: '',
    first_name: '',
    last_name: '',
  },
  items: [{ ...initItem }],
  total: initialPrice,
};

const user = {
  address: null,
  billing: null,
  email: '',
  first_name: '',
  household_members: [],
  travel_protection: {
    id: '',
    title: '',
    note: '',
    otp: false,
    hidden: false,
    price: initialPrice,
    period: { unit: TimePeriodOutputUnitEnum.Year, number: 1 },
  },
  last_name: '',
  phone: null,
  rank: '',
  tp_purchased: false,
  military: {
    branch: null,
    era: null,
    rank: null,
  },
};

const initEnrollPreviewData = {
  lines: {
    primary: initLineGroup,
    contribution: [{ ...initItem, type: PurchaseBriefLineItemOutputTypeEnum.Contribution }],
    total: initialPrice,
    household_members: [],
  },
  subscription: {
    autorenew: false,
    expires: 0,
  },
};

const initEnrollData = {
  enrollPreview: initEnrollPreviewData,
  error: undefined,
  isLoading: false,
};

const initUserEnroll = {
  user,
  plans: [],
  ranks: {},
  branches: {},
  promotion: null,
  card: null,
  config: {},
  tp_purchased: false,
  brand_queue: null,
  // household_members: [],
  household_members: {
    active: [],
    inactive: [],
  },
};

const subscriptionDetails = {
  affinity: '',
  tier: '',
  first_name: '',
  last_name: '',
  address: null,
  phone: '',
  billing: null,
  rank: '',
  verification_status: '',
  household_members: null,
  household_member: null,
  email: '',
  promotion: {
    enroll: null,
    renew: null,
  },
  subscription: {
    autorenew: true,
    autorenew_disabled_on: null,
    plan: '',
    extension: '',
    travel_protection: null,
    expires: 0,
    paused_until: null,
  },
  payment_failed: false,
  roles: [],
  branch: '',
  member_id: '',
  travel_code: '',
  trial_used: false,
};

const initialState: CheckoutDetailsContextType = {
  selectedPlan: null,
  selectedProducts: {},
  details: {
    donate: {
      donate: '0',
      checkedB: false,
      donate_other: null,
    },
  },
  user,
  brand_queue: null,
  // travelProtection: null,
  subscriptionDetails: null,
  previewDetails: initEnrollData,
  config: {},
};

export { initialState, initialPrice, initEnrollData, initUserEnroll, initEnrollPreviewData };
export type { CheckoutDetailsContextType, ReviewDetailsType };
