import React, { PropsWithChildren, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { auth, authRef, dbRef, persistenceNone, persistenceSession } from 'services/Firebase';
import CircularProgress from '@mui/material/CircularProgress';
import { getSearchParam, hasSearchParam, getCurrentUrl } from 'utils/appshell';
import Cookies from 'services/Cookies';
import AppShell, { User } from 'services/Appshell';
import middlewareHandler from 'services/middleware';

const useStyles = makeStyles((theme) =>
  createStyles({
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
    },
  })
);

type Props = PropsWithChildren<{
  isDev?: boolean;
  loader?: React.ReactElement;
  middleware?: string[];
  name?: string;
  render: (app: typeof AppShell) => React.ReactElement | null;
}>;

const AppshellWrapper = ({ name = 'unknown', isDev = false, loader, middleware = [], render }: Props): JSX.Element => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const signInWithCustomToken = async (token: string) => {
    try {
      const userCredential = await authRef.signInWithCustomToken(token);
      if (userCredential.user) {
        const { email, displayName, uid } = userCredential.user || {};
        AppShell.user = { email: email!, displayName: displayName!, uid };
      }
    } catch (error) {
      //@ts-ignore-next-line
      if (!error.code || 'auth/invalid-custom-token' !== error.code) {
        throw error;
      }
    }
  };
  useEffect(() => {
    AppShell.setName(name);
    // AppShell.setContext(context);
    AppShell.setAuth(auth);
    authRef.setPersistence(isDev ? persistenceSession : persistenceNone);
    authRef.onAuthStateChanged(async (user) => {
      const { email, displayName, uid } = user || {};
      const sessionDbId = getSearchParam('sessionId');
      let m8eToken = Cookies.get('m8eToken') ?? null;
      let customToken = Cookies.get('loginTokenSession');
      const m8eEnabled = !!Cookies.get('masquerade');
      const m8eSearchToken = getSearchParam('m8e_token');
      if (m8eEnabled && m8eSearchToken) {
        m8eToken = m8eSearchToken;
        Cookies.set('m8eToken', m8eSearchToken, { expires: 1 / 24 });
      }
      if (!m8eEnabled && m8eToken) {
        m8eToken = null;
        Cookies.remove('m8eToken');
        await authRef.signOut();
      }
      if (!uid && m8eToken) {
        await signInWithCustomToken(m8eToken);
      } else if (uid) {
        AppShell.user = { email: email!, displayName: displayName!, uid };
      } else if (!customToken && sessionDbId) {
        const doc = await dbRef
          .collection('sessions')
          .doc(sessionDbId)
          .collection('login_session')
          .doc(sessionDbId)
          .get();
        const session = doc.exists ? doc.data() : {};
        customToken = session?.loginToken?.data;
      } else {
        Cookies.set('cookieSupport', 'true', {
          expires: 1 / 24,
        });
      }
      if (customToken && !m8eToken) {
        await signInWithCustomToken(customToken);
      }
      if (AppShell.user) {
        const { uid } = AppShell.user;
        const doc = await dbRef.collection('veterans').doc(uid).get();
        AppShell.user.data = doc.exists ? doc.data() : null;
      }
      setUser(AppShell.user || null);
      if (hasSearchParam('sessionId') || hasSearchParam('m8e_token')) {
        history.pushState({}, '', getCurrentUrl());
      }
    });
  }, []);
  useEffect(() => {
    (async () => {
      if (user === undefined) {
        return;
      }
      const ret = await new middlewareHandler(name, middleware, user, isDev).process();
      if (!ret) {
        setLoaded(true);
      }
    })();
  }, [user ? user.uid : user]);
  return (
    <>
      <div className='appShellContent'>
        {loaded
          ? render(AppShell)
          : loader ?? (
              <div className={classes.loader}>
                <div>
                  <CircularProgress />
                </div>
              </div>
            )}
      </div>
    </>
  );
};

export default AppshellWrapper;
