import { BaseAttributes, baseAttributes } from './helpers';

export type PromotionClicked = BaseAttributes & {
  code: string;
  creative: string;
  description: string;
  name: string;
  promotionId: string;
};

export default function promotionClicked({ name, creative, description, promotionId, code }: Data): PromotionClicked {
  return {
    ...baseAttributes(),
    code,
    creative,
    description,
    name,
    promotionId,
  };
}
